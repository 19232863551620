#solution-slides {
  background-color: white;
  position: relative;
  padding: 0;
  height: 400vh;
  @supports (height: 400dvh) {
    height: 400dvh
  }
  @media screen and (min-width: 769px) {
    height: 420vh;
    @supports (height: 420dvh) {
      height: 420dvh;
    }
  }
  [id="solution-slide-detection"] {
    &::before {
      opacity: 0.2;
    }
  }
  .landing-section-content {
    display: block;
  }
}