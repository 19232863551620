[id='masthead'] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 16px 10px 0;
  height: 66px;
  @media screen and (min-width: 769px) {
    height: 90px;
    padding-top: 30px;
  }
  /* &.simple-mode {
  } */
  &.header-hero-animation {
    @media screen and (min-width: 769px) {
      .container {
        background-color: #ecf0fe55;
      }
    }
  }
  .container {
    display: flex;
    padding: 13px 6px 13px 13px;
    align-items: center;
    justify-content: space-between;
    max-height: 100%;
    background-color: #ffffff55;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 40px;
    @media screen and (min-width: 769px) {
      padding: 20px 12px;
      gap: 10px;
      display: flex;
    }
    > a {
      display: block;
      line-height: 1;
    }
    > section {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  ul {
    display: none;
    gap: 5px;
    a {
      display: block;
      padding: 5px;
      color: var(--base-font-color);
      &.selected {
        font-weight: 600;
        &::after {
          background-color: #324eef;
        }
      }
    }
    @media screen and (min-width: 769px) {
      gap: 10px;
      display: flex;
    }
    @media screen and (min-width: 1025px) {
      gap: 15px;
      a {
        padding: 5px 10px;
        font-size: 22px;
      }
    }
  }
  img {
    max-height: 100%;
    max-width: 120px;
    margin-top: 2px;
    @media screen and (min-width: 769px) {
      margin-left: 12px;
      max-width: 150px;
      width: 150px;
      height: 35px;
    }
  }
  .button {
    color: white;
    border-color: #175CD3;
    background-color: #175CD3;
    font-weight: 500;
    line-height: 1;
    border: none;
    padding: 12px 16px;
    @media screen and (min-width: 1025px) {
      font-size: 22px;
      line-height: 1;
      padding: 10px 24px;
    }
    &.button-light {
      background-color: white;
      color: #324eef;
    }
  }
  .icon {
    font-size: 22px;
    cursor: pointer;
  }
  + [id='mobile-navigation'] {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
    translate: 20%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #324eef;
    color: white;
    z-index: 10;
    [id="mobile-navigation-content"] {
      padding-top: 10vh;
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;
      text-align: center;
      > img {
        max-width: 50%;
        margin: auto;
      }
    }
    nav {
      display: grid;
      place-items: center;
    }
    ul {
      display: grid;
      gap: 5px;
    }
    a {
      padding: 10px;
      font-size: 32px;
      color: #6e97dd;
      font-weight: 500;
      &.selected {
        color: white;
        font-weight: 700;
        &::after {
          background-color: #001c4d;
        }
      }
    }
    .icon-close {
      font-size: 24px;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    &.open {
      opacity: 1;
      pointer-events: auto;
      translate: 0;
    }
  }
}

a.selected {
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: #001c4d;
    border-radius: 100%;
    bottom: 60%;
    right: 0;
    position: absolute;
  }
}
