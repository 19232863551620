#solutions {
  color: white;
  position: relative;
  padding-bottom: 0;
  height: 130vh;
  @supports (height: 200dvh) {
    height: 130dvh
  }
  @media screen and (min-width: 769px) {
    height: 180vh;
    @supports (height: 160dvh) {
      height: 180dvh
    }
  }

  [id="solutions-grid"] {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(4, minmax(0, 1fr));
    @media screen and (min-width: 769px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: minmax(0, 1fr);
    }
    > article {
      display: grid;
      place-items: center;
      text-align: center;
      box-shadow: inset 0 0 0 1000px #00000029;
      background-position: center;
      background-size: cover;
      opacity: 0;
      translate: 0 30%;
      transition: all 0.9s var(--cubic-bezier);
      a {
        color: white;
      }
      &.enter {
        opacity: 1;
        translate: 0 0;
      }
    }
    h1 {
      max-width: 200px;
      text-wrap: balance;
      font-size: 20px;
      line-height: 1.2;
      strong {
        display: block;
        margin-bottom: .7em;
      }
      @media screen and (min-width: 769px) {
        font-size: 24px;
      }
      @media screen and (min-width: 1025px) {
        font-size: 30px;
      }
    }
  }
  > .enter-sticky {
    article.enter a {
      pointer-events: auto;
    }
  }
}