#designed {
  background-color: white;
  position: relative;
  padding-bottom: 0;
  height: 130vh;
  @supports (height: 130dvh) {
    height: 130dvh
  }
  @media screen and (min-width: 769px) {
    height: 180vh;
    @supports (height: 180dvh) {
      height: 180dvh
    }
  }
  .landing-section-content {
    display: grid;
    place-items: center;
    background-color: white;
  }
  .designed-fan-wrapper {
    position: absolute;
    width: 99.8vw;
    overflow: hidden;
    @media screen and (min-width: 769px) {
      width: 49.9vw;
    }
  }
  .designed-fan-wrapper-left {
    top: 0%;
    left: 0%;
  }
  .designed-fan-wrapper-right {
    right: 0%;
    bottom: 0%;
    .animated-fan {
      transition-delay: 0.1s;
    }
  }
  .container {
    position: relative;
    text-align: center;
    padding: 5vh 0 0;
  }
  .video-app-wrapper {
    opacity: 0;
    translate: 0 30%;
    transition: all 0.9s var(--cubic-bezier);
    max-height: calc(90vh - 100px);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    overflow: hidden;
    display: block;
    margin: 10px;
    border-radius: 4%;
    max-width: max-content;
    margin: auto;
    &.enter {
      opacity: 1;
      translate: 0 0;
    }
  }
  video {
    object-fit: contain;
    display: block;
    max-width: 100%;
  }
}
