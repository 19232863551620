#intro {
  background-color: white;
  position: relative;
  padding-bottom: 0;
  height: 130vh;
  @supports (height: 130dvh) {
    height: 130dvh
  }
  @media screen and (min-width: 769px) {
    height: 180vh;
    @supports (height: 300dvh) {
      height: 180dvh
    }
  }
  .landing-section-content {
    display: grid;
    place-items: center;
    background-color: white;
  }
  .intro-fan-wrapper {
    position: absolute;
    width: 99.8vw;
    overflow: hidden;
    @media screen and (min-width: 769px) {
      width: 49.9vw;
    }
  }
  .intro-fan-wrapper-left {
    top: 0%;
    left: 0%;
    @media screen and (min-width: 769px) {
      top: -25%;
    }
  }
  .intro-fan-wrapper-right {
    right: 0%;
    bottom: 0%;
    .animated-fan {
      transition-delay: 0.1s;
    }
    @media screen and (min-width: 769px) {
      bottom: -5%;
    }
  }
  [id="scroll-anchor"] {
    position: absolute;
    top: 22%;
  }
  [id="intro-cards"] {
    perspective: 1000px;
    display: grid;
    gap: 20px;
    @media screen and (min-width: 769px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: 1fr;
    }
    > div {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      &.left-wrapper {
        transform: translate3d(20%, 20%, -60vh);
      }
      &.right-wrapper {
        transform: translate3d(-20%, 20%, -60vh);
        transition-delay: 0.5s;
      }
      &.enter {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}
