.animated-fan {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-clip-path: circle(100% at 0 100%);
  clip-path: circle(100% at 0 100%);
  aspect-ratio: 1 / 1;
  transform: rotate(-90deg);
  transition: transform 0.9s ease-in-out;
  &.bottom-left {
    clip-path: circle(100% at 0 100%);
    transform-origin: left bottom;
  }
  &.bottom-right {
    clip-path: circle(100% at 100% 100%);
    transform-origin: right bottom;
  }
  &.top-left {
    clip-path: circle(100% at 0 0);
    transform-origin: left top;
  }
  &.top-right {
    clip-path: circle(100% at 100% 0);
    transform-origin: right top;
  }
  &.automatic {
    animation: automatic .9s ease-in-out both;
  }
}

@keyframes automatic {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
