.spinner {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  margin: auto;
  div {
    width: 8%;
    height: 24%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(57, 200, 138, 0.2);
    animation: fade458 1s linear infinite;
    &.bar1 {
      transform: rotate(0deg) translate(0, -130%);
      animation-delay: 0s;
    }
    &.bar2 {
      transform: rotate(36deg) translate(0, -130%);
      animation-delay: -1.1s;
    }
    &.bar3 {
      transform: rotate(72deg) translate(0, -130%);
      animation-delay: -1s;
    }
    &.bar4 {
      transform: rotate(108deg) translate(0, -130%);
      animation-delay: -0.9s;
    }
    &.bar5 {
      transform: rotate(144deg) translate(0, -130%);
      animation-delay: -0.8s;
    }
    &.bar6 {
      transform: rotate(180deg) translate(0, -130%);
      animation-delay: -0.7s;
    }
    &.bar7 {
      transform: rotate(216deg) translate(0, -130%);
      animation-delay: -0.6s;
    }
    &.bar8 {
      transform: rotate(252deg) translate(0, -130%);
      animation-delay: -0.5s;
    }
    &.bar9 {
      transform: rotate(288deg) translate(0, -130%);
      animation-delay: -0.4s;
    }
    &.bar10 {
      transform: rotate(324deg) translate(0, -130%);
      animation-delay: -0.3s;
    }
  }
}

@keyframes fade458 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
