#verification {
  background-color: white;
  position: relative;
  padding-bottom: 0;
  height: 150vh;
  @supports (height: 200dvh) {
    height: 150dvh
  }
  @media screen and (min-width: 769px) {
    height: 260vh;
    @supports (height: 330dvh) {
      height: 260dvh
    }
  }
  .landing-section-content {
    display: grid;
    place-items: center;
    background-color: #175CD3;
  }
  .lines {
    padding: 0 10px;
  }
  .line {
    text-align: center;
    position: relative;
    font-size: min(7.5vw, 10vh);
    line-height: 1.3;
    font-weight: 800;
    translate: -10%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    color: white;
    @media screen and (min-width: 769px) {
      font-size: min(3.5vw, 10vh);
    }
    &.enter {
      translate: 0%;
      opacity: 1;
    }
  }
  .line-mask {
    transition: width 0.45s ease-in-out;
    background-color: #175CD3cc;
    position: absolute;
    top: .1em;
    right: 0;
    bottom: -.1em;
    width: 100%;
    z-index: 1;
  }
}
