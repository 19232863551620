#contact {
  background-color: white;
  position: relative;
  z-index: 1;
  &.landing-section {
    display: grid;
    place-items: center;
  }
  .container {
    max-width: 472px;
    position: sticky;
    top: calc(25% - 220px);
    padding: 100px 5vw 0;
    @media screen and (min-width: 769px) {
      max-width: 472px;
      position: static;
      padding: 0 10px;
    }
    @media screen and (min-width: 1025px) {
      max-width: 760px;
    }
    @media screen and (min-width: 1720px) {
      max-width: 960px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    height: 20vh;
    width: 95%;
    left: 0;
    min-width: var(--breakpoint-tablet);
    background-image: url('/vectors/contact.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (min-width: 769px) {
      translate: 0 -50%;
    }
  }
  p {
    color: #898989;
    font-weight: 600;
    letter-spacing: -0.02em;
    position: relative;
    strong {
      display: block;
      color: var(--base-font-color);
      font-weight: 700;
    }
    font-size: clamp(22px, 2.8vh, 26px);
    line-height: 1.5;
    @media screen and (min-width: 769px) {
      font-size: 24px;
      line-height: 1.4;
    }
    @media screen and (min-width: 1025px) {
      font-size: 40px;
      line-height: 1.4;
    }
    @media screen and (min-width: 1720px) {
      font-size: 50px;
      line-height: 1.4;
    }
  }
  button {
    color: white;
    border-color: #324eef;
    background-color: #324eef;
    font-weight: 500;
    font-size: 3vh;
    @media screen and (min-width: 769px) {
      padding: 10px 22px;
      font-size: 20px;
      line-height: 1;
    }
    @media screen and (min-width: 1025px) {
      padding: 12px 22px;
      font-size: 28px;
      line-height: 1;
    }
  }
}