[id="anchor-slides"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  .anchor-slide {
    height: 100vh;
    > .solution-slide {
      opacity: 1;
      translate: 0;
    }
    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }
  @media screen and (min-width: 769px) {
    /* Divide amount by height of 4 viewports */
    .anchor-slide {
      height: 100vh;
      opacity: 0;
      &:nth-child(1) {
        height: 60vh;
      }
      &:nth-child(4) {
        height: 160vh;
      }
      > .solution-slide {
        display: none;
      }
      @supports (height: 100dvh) {
        height: 100dvh;
        &:nth-child(1) {
          height: 70dvh;
        }
        &:nth-child(4) {
          height: 160dvh;
        }
      }
    }
  }
  [id="anchor-bar"] {
    position: fixed;
    bottom: 3vh;
    left: 5vw;
    width: 90vw;
    z-index: 1;
    animation: fadeEnter 0.5s ease-in-out;
    display: none;
    @media screen and (min-width: 769px) {
      display: block;
    }
    .index-section {
      color: white;
      text-shadow: 0 0 3px var(--primary-color);
      padding-bottom: 10px;
      font-weight: 500;
    }
    .anchors {
      display: flex;
      gap: 1%;
      > a {
        border-radius: 2px;
        width: 100%;
        flex: 1 1 25%;
        display: block;
        height: 6px;
        background-color: #000E27;
        pointer-events: auto;
        transition: all 0.4s ease-in-out;
        &.active {
          background-color: #174D79;
          opacity: 0.7;
        }
      }

    }
  }
}