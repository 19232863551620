.solution-slide {
  height: 100%;
  width: 100%;
  text-align: center;
  display: grid;
  place-items: center;
  translate: -10%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  @media screen and (min-width: 769px) {
    position: absolute;
    &.enter {
      translate: 0;
      opacity: 1;
      .tilt-image {
        pointer-events: auto;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 40%;
    height: 20vh;
    width: 95%;
    left: 0;
    min-width: 900px;
    background-image: url('/vectors/solution.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (min-width: 769px) {
      translate: 0 -50%;
      right: 2%;
      left: auto;
    }
  }
  .content {
    position: relative;
    padding: 5vh 10px 0;
  }
  h1 {
    font-weight: 500;
    margin: 5vh 0 0.5em;
    font-size: 26px;
    line-height: 1.2;
    @media screen and (min-width: 769px) {
      font-size: 40px;
    }
  }
  p {
    text-wrap: balance;
    max-width: 500px;
    margin: 0 auto;
    font-size: 19px;
    @media screen and (min-width: 769px) {
      text-wrap: pretty;
      line-height: 1.25;
      font-size: 24px;
    }
  }
  &[id='solution-slide-monitor'] {
    background-color: #031d4b;
    h1,
    p {
      color: white;
    }
  }
  &[id='solution-slide-verification'] {
    background-color: #175cd3;
    h1,
    p {
      color: white;
    }
  }
  &[id='solution-slide-detection'] {
    background-color: #81d1ff;
    h1 {
      color: #043b5a;
    }
    p {
      color: #18609a;
    }
  }
  &[id='solution-slide-alerts'] {
    background-color: #031d4b;
    h1,
    p {
      color: white;
    }
  }
}
