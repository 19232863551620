[id="colophon"] {
  background-color: #ECF0FE;
  position: relative;
  z-index: 1;
  padding: 10px 0;
  &.is-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 100%;
  }
  ul {
    text-align: center;
    display: block;
    width: 100%;
    @media screen and (min-width: 769px) {
      display: flex;
      gap: 5px;
      text-align: left;
    }
    img {
      max-width: 120px;
      margin-bottom: 10px;
      display: none;
    }
    a {
      display: block;
      padding: 5px 10px;
      color: var(--base-font-color);
      font-size: 21px;
    }
  }
  @media screen and (max-width: 768px) {
    background-color: #0B2156;
    padding-top: 8vh;
    position: relative;
    overflow: hidden;
    clip-path: ellipse(70% 100% at 50% 100%);
    ul {
      a {
        color: white;
        padding: 2px 10px;
        margin: 4px 0;
        font-size: 18px;
      }
      img {
        display: inline;
      }
    }
  }
}