#cookie-settings-button {
  margin: 40px auto;
  background-color: #218BFF;
  border-radius: 60px;
  color: white;
  font-weight: 600;
  position: relative;
  display: block;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--primary-color);
  }
}
