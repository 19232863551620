:root {
  --dark-blue: #0B2156;
  --primary-color: #324EEF;
  --secondary-color: #fff;
  --font-size: 16px;
  --base-font-color: #282828;
  --base-font-family: 'Onest', 'Arial', 'Helvetica', 'Ubuntu', 'Open Sans',
    'Segoe UI', 'Roboto', serif;
  --breakpoint-mobile: 480px;
  --breakpoint-tablet: 768px;
  --breakpoint-laptop: 1024px;
  --breakpoint-desktop: 1440px;
  --breakpoint-smartTv: 1920px;
  --cubic-bezier: cubic-bezier(.48,0,.31,1.14);
}

@property --line-clamp {
  syntax: '<integer>';
  inherits: false;
  initial-value: 4;
}

html {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh
  }
}

body {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  color: var(--base-font-color, #000);
  background-color: var(--secondary-color);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--base-font-family);
  line-height: 1.5;
  font-weight: 400;
  /* color-scheme: light dark; */
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

embed,
iframe,
object {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: initial;
}

a {
  text-decoration: none;
  transition: color 0.1s linear;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-left: none;
  border-right: none;
  border-top: none;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

input, select, textarea {
  border-radius: 12px;
  border: 1px solid #eee;
  padding: 12px;
  option {
    background-color: white;
    color: var(--base-font-color);
  }
}
