#logo-animation {
  .container {
    max-width: 960px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 30px;
  }
  img {
    display: block;
    position: relative;
  }
  .symbol {
    animation: symbolTranslate 0.4s ease-in-out 3.5s both;
    max-width: 17%;
    min-width: 0;
    flex: 0 0 auto;
  }
  .text {
    opacity: 0;
    animation: textOpacity 2s ease 4s both;
    max-width: 100%;
    min-width: 0;
    flex: 1 1 auto;
  }
}

@keyframes symbolTranslate {
  0% {
    left: 40%;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    left: 0;
  }
}

@keyframes textOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}