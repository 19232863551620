#hero-animation {
  padding: 0.1px;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
  height: 100vh;
  z-index: 1;
  background-color: #ECF0FE;
  @supports (height: 100dvh) {
    height: 100dvh
  }
}