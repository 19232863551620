.data-fact {
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  padding: 20px;
  background-color: #031D4B;
  color: white;
  position: sticky;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  @supports (height: 620dvh) {
    margin-top: 20dvh;
    + .data-fact {
      margin-top: 40dvh;
    }
  }
  @media screen and (min-width: 769px) {
    margin-top: 40vh;
    + .data-fact {
      margin-top: 60vh;
    }
  }
  h1 {
    font-size: 26px;
    line-height: 1.4;
    font-weight: 400;
    strong {
      font-size: 36px;
      font-weight: 900;
      display: block;
    }
  }
  h2 {
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease-in-out;
    left: 50%;
    translate: -50% -5%;
  }
  h2, h3 {
    margin: 0;
    line-height: 1;
    text-align: center;
  }
  > header {
    flex: 0 0 auto;
  }
  > section {
    flex: 1 1 auto;
    min-height: 0;
    max-height: 100%;
  }
  @media screen and (min-width: 769px) {
    header {
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: space-between;
      > * {
        min-width: 120px;
      }
    }
    h1 {
      font-size: 55px;
      line-height: 1.1;
      strong {
        font-size: 100px;
      }
    }
    &.revealed h2 {
      font-size: 24px;
      line-height: 1;
      font-weight: 400;
      opacity: 1;
      translate: -50% 0%;
      strong {
        font-weight: 900;
      }
    }
  }
  section {
    text-align: center;
    display: grid;
    place-items: center;
  }
  &[id="data-fact-1"] {
    top: 70px;
    height: calc(100vh - 70px);
    @supports (height: 100dvh) {
      height: calc(100dvh - 70px);
    }
    @media screen and (min-width: 769px) {
      top: 100px;
      height: calc(100vh - 100px);
    }
    h1 {
      color: #2A5CB6;
      strong {
        color: white;
      }
    }
  }
  &[id="data-fact-2"] {
    top: 130px;
    background-color: #175CD3;
    height: calc(100vh - 120px);
    @supports (height: 100dvh) {
      height: calc(100dvh - 130px);
    }
    @media screen and (min-width: 769px) {
      top: 170px;
      height: calc(100vh - 170px);
    }
    h1 {
      color: #669CFA;
      strong {
        color: white;
      }
    }
  }
  &[id="data-fact-3"] {
    top: 190px;
    background-color: #81D1FF;
    height: calc(100vh - 190px);
    color: #043B5A;
    @supports (height: 100dvh) {
      height: calc(100dvh - 190px);
    }
    @media screen and (min-width: 769px) {
      top: 240px;
      height: calc(100vh - 240px);
    }
    h1 {
      color: #3785B1;
      strong {
        color: #043B5A;
      }
    }
  }
}
