.bubbles {
  position: relative;
  display: flex;
  width: 120%;
  height: 110%;
  left: -10%;
  will-change: transform;
  transition: transform 0.2s ease-in-out;
  transform: translate3d(0, 0, 20px);
  &:hover {
    transform: translate3d(0, 0, 120px);
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 0 0 50%;
    align-items: flex-start;
  }
  .right-side {
    align-items: flex-end;
  }
  .bubble {
    background-color: white;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 12px;
    box-shadow: 2px 2px 20px #00000022;
    white-space: nowrap;
    font-weight: 500;
    position: absolute;
    @media screen and (min-width: 769px) {
      font-size: 16px;
      padding: 10px 18px;
      border-radius: 16px;
    }
  }
  &[id="bubbles-monitor"] {
    .bubble-telegram {
      top: 25%;
    }
    .bubble-tiktok {
      top: 75%;
    }
    .bubble-facebook {
      top: 13%;
      right: 15%;
    }
    .bubble-youtube {
      right: 5%;
      top: 25%;
    }
    .bubble-podcasts {
      top: 60%;
    }
    .bubble-blogs {
      right: 10%;
      top: 80%;
    }
  }
  &[id="bubbles-detection"] {
    .bubble {
      position: relative;
      font-size: 10px;
      border-radius: 10px;
    }
    .left-side {
      margin-left: -6%;
      padding-top: 8%;
      @media screen and (min-width: 769px) {
        margin-left: -20%;
        padding-top: 10%;
        gap: 10px;
        .bubble {
          font-size: 16px;
          border-radius: 16px;
        }
      }
    }
  }
  &[id="bubbles-verification"] {
    [id="bubbles-verification-1"] {
      top: 18%;
      @media screen and (min-width: 769px) {
        left: -10%;
      }
    }
    [id="bubbles-verification-2"] {
      top: 55%;
      @media screen and (min-width: 769px) {
        left: -10%;
      }
    }
    [id="bubbles-verification-3"] {
      right: 5%;
      top: 32%;
      @media screen and (min-width: 769px) {
        right: -10%;
      }
    }
  }
  &[id="bubbles-alerts"] {
    [id="bubbles-alerts-1"] {
      top: 18%;
      @media screen and (min-width: 769px) {
        left: -10%;
      }
    }
    [id="bubbles-alerts-2"] {
      top: 55%;
      @media screen and (min-width: 769px) {
        left: -10%;
      }
    }
    [id="bubbles-alerts-3"] {
      right: 5%;
      top: 32%;
      @media screen and (min-width: 769px) {
        right: -10%;
      }
    }
    [id="bubbles-alerts-4"] {
      right: 5%;
      top: 82%;
      @media screen and (min-width: 769px) {
        right: -10%;
      }
    }
  }
}
