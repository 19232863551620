#radar-video {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: fadeEnter 1s ease 5s both;
  width: 100vw;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh
  }
}