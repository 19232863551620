#landing {
  width: 100%;
  overflow-x: hidden;
  .landing-section {
    position: relative;
    padding: 20px 0;
    min-height: 100vh;
    @supports (min-height: 100dvh) {
      min-height: 100dvh
    }
  }
  .landing-section-slide {
    .landing-section-content {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      height: 100vh;
      pointer-events: none;
      z-index: 1;
      opacity: 0;
      translate: 0 50%;
      transition: all 0.3s ease-in-out;
      &.enter-sticky {
        opacity: 1;
        translate: 0 0;
      }
      @supports (height: 100dvh) {
        height: 100dvh
      }
    }
  }
  .landing-section-sticky {
    padding: 0;
    .landing-section-content {
      position: sticky;
      top: 0;
      height: 100vh;
      overflow: hidden;
      @supports (height: 100dvh) {
        height: 100dvh
      }
    }
  }
  &.view-hero-animation {
    .at-top {
      animation-delay: 4.5s;
    }
  }
  footer {
    @media screen and (min-width: 769px) {
      background-color: white;
    }
  }
}
