.intro-card {
  background-color: #175CD3;
  color: white;
  border-radius: 18px;
  padding: 40px;
  text-align: center;
  height: 100%;
  @media screen and (min-width: 1025px) {
    border-radius: 28px;
  }
  > video {
    max-height: 20vh;
    @supports (height: 25dvh) {
      max-height: 25dvh;
    }
    @media screen and (max-height: 480px) {
      max-height: 14vh;
      @supports (height: 10dvh) {
        max-height: 15dvh;
      }
    }
    @media screen and (min-width: 1720px) {
      max-height: 30vh;
    }
  }
  p {
    text-align: left;
    font-weight: 500;
    margin: 0 auto;
    strong {
      color: var(--dark-blue);
      font-weight: 700;
    }
    @media screen and (min-width: 769px) {
      strong {
        flex: 0 0 auto;
        color: var(--dark-blue);
        font-weight: 700;
        display: block;
      }
      span {
        display: flex;
        flex-wrap: wrap;
        text-wrap: balance;
      }
      font-size: 25px;
      line-height: 1.2;
      max-width: 250px;
    }
    @media screen and (min-width: 1025px) {
      font-size: 34px;
      line-height: 1.2;
      max-width: 340px;
    }
    @media screen and (min-width: 1720px) {
      font-size: 44px;
      line-height: 1.2;
      padding: 20px 50px;
      max-width: 540px;
    }
  }
}
