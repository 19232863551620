#data-facts {
  background-color: white;
  position: relative;
  padding-bottom: 0;
  z-index: 1;
  height: 420vh;
  @supports (height: 420dvh) {
    height: 420dvh
  }
  @media screen and (min-width: 769px) {
    height: 500vh;
    @supports (height: 620dvh) {
      height: 500dvh
    }
  }
  &::before {
    position: sticky;
    display: block;
    margin: 40% auto 0;
    top: 15%;
    height: 20vh;
    width: 95%;
    left: 0;
    content: '';
    min-width: var(--breakpoint-tablet);
    background-image: url('/vectors/data-facts-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
