[id="scroll-down"] {
  border-radius: 100px;
  padding: 8px 6px;
  display: none;
  place-items: center;
  animation: fadeEnter 1s ease-in-out 1s both;
  width: max-content;
  margin: auto;
  position: fixed;
  left: 50%;
  translate: -50%;
  bottom: 20px;
  z-index: 1;
  pointer-events: none;
  transition: all 0.3s ease;
  background: #00000066;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  color: white;
  z-index: 9;
  &.at-top {
    display: grid;
    pointer-events: auto;
    background: linear-gradient(#80B4FF, #61D7F6);
    [id="mouse-wheel"], [id="scroll-down-text"], [id="mouse-body"], .icon {
      color: #1469E2;
      border-color: #1469E2;
    }
    [id="mouse-body"] {
      background-color: white;
    }
  }
  [id="mouse-body"] {
    border: 2px solid white;
    border-radius: 23px;
    height: 27px;
    width: 20px;
    transition: all 0.3s ease;
  }
  [id="mouse-wheel"] {
    border: 2px solid white;
    border-radius: 10px;
    background-color: white;
    position: relative;
    margin: 0 auto;
    animation: wheel_animation 1.25s linear infinite;
    width: 4px;
    height: 6px;
    transition: all 0.3s ease;
  }
  [id="scroll-down-text"] {
    padding: 10px 0 0;
    color: white;
    transition: all 0.3s ease;
    font-size: 13px;
  }
  @media screen and (min-width: 769px) {
    padding: 16px 12px;
    bottom: 30px;
    [id="mouse-body"] {
      height: 33px;
      width: 23px;
    }
    [id="scroll-down-text"] {
      font-size: 16px;
    }
  }
}

@keyframes wheel_animation {
  0% {
    opacity: 0;
    top: 4px;
  }
  50% {
    opacity: 1;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 20px;
  }
}
