[id="legal"] {
  background-color: #ECF0FE;
  .legal-content {
    max-width: 960px;
    padding: 90px 16px 60px;
    margin: auto;
    overflow: hidden;
    h1, h2 {
      color: var(--dark-blue);
      font-weight: 900;
    }
    strong {
      color: var(--dark-blue);
    }
    h1 {
      line-height: 1.2;
      @media screen and (min-width: 769px) {
        font-size: 42px;
      }
    }
    h2 {
      line-height: 1.2;
      @media screen and (min-width: 769px) {
        font-size: 30px;
      }
    }
    p, ul, ol {
      @media screen and (min-width: 769px) {
        font-size: 20px;
      }
    }
    ul {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      unicode-bidi: isolate;
      li {
        list-style: disc;
      }
    }
    a {
      color: var(--primary-color);
      text-decoration: underline;
    }
    .table-wrapper {
      max-width: 100%;
      overflow-x: auto;
      td {
        min-width: 170px;
        @media screen and (min-width: 769px) {
          min-width: 100px;
        }
      }
    }
  }
}