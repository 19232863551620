@starting-style {
  [id="contact-dialog"]  {
    opacity: 0;
    transform: scale(0.9);
  }
}
[id="contact-dialog"] {
  max-width: min(628px, 95vw);
  max-height: min(628px, 95vh);
  height: 100%;
  width: 100%;
  display: block;
  background-color: white;
  padding: 20px 16px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  transition: opacity 0.3s ease, transform 0.5s ease;
  @media screen and (min-width: 769px) {
    padding: 30px;
  }
  > header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
    button {
      padding: 10px 16px;
      border-radius: 10px;
      display: flex;
      gap: 5px;
      align-items: baseline;
    }
    h1 {
      margin: 0;
      color: var(--base-font-color);
    }
  }
  > form {
    height: auto;
    button {
      border-color: #218BFF;
      background-color: #218BFF;
      color: white;
      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
  select, option {
    background-color: white;
    color: var(--base-font-color);
  }
  select, input, textarea {
    outline-color: #218BFF55;
  }
  .success-feedback {
    margin: auto;
    text-align: center;
    animation: textOpacity 2s ease both;
    img {
      height: clamp(120px, 30vh, 240px);
      animation: textOpacity 2s ease both;
    }
    h1 {
      color: #218BFF;
      max-width: 300px;
      margin: 20px auto 0;
      line-height: 1.3;
      animation: textOpacity 2s ease both 1s;
    }
  }
  .feedback {
    min-height: 2.3em;
    line-height: 1.2;
    .error-text {
      color: red;
    }
    .success-text {
      color: var(--primary-color)
    }
  }
  fieldset {
    margin: 12px 0;
    padding: 0;
    border: none;
    display: grid;
    gap: 12px;
    &[id="top-fieldset"] {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .form-privacy {
    font-size: 13px;
    line-height: 1.2;
    a {
      color: var(--base-font-color);
      text-decoration: underline;
      font-weight: 600;
    }
    @media screen and (min-width: 769px) {
      font-size: 16px;
    }
  }
  label {
    align-items: flex-start;
    display: flex;
    align-items: baseline;
    margin: 0;
    + .form-privacy {
      margin-top: -6px;
      margin-left: 21px;
    }
    @media screen and (min-width: 769px) {
      align-items: center;
      + .form-privacy {
        margin-left: 28px;
      }
    }
    input {
      appearance: none;
      display: none;
      + span {
        display: grid;
        background-color: #ddd;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        margin-top: -4px;
        vertical-align: middle;
        margin-right: 6px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        place-items: center;
        @media screen and (min-width: 769px) {
          margin-right: 10px;
          width: 18px;
          height: 18px;
        }
        img {
          opacity: 0;
          max-width: 60%;
        }
      }
      &:checked + span {
        background-color: #218BFF;
        img {
          opacity: 1;
        }
      }
    }
  }
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}