#didomi-host {
  #didomi-notice {
    background-color: #ffffffaa !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    max-width: 820px;
    border-radius: 30px;
    box-shadow: 0 0 5px #889AFF44!important;
    @media screen and (min-width: 769px) {
      padding: 30px !important;
      left: auto !important;
      right: 20px !important;
      top: auto !important;
      bottom: 20px !important;
    }
    a {
      color: #324EEF !important;
    }
    #buttons {
      flex-direction: column !important;
      gap: 10px;
      > button {
        margin: 0 !important;
        border-radius: 50px !important;
      }
    }
    #didomi-notice-learn-more-button {
      background-color: white !important;
    }
    #didomi-notice-disagree-button {
      background-color: #889AFF !important;
      color: white !important;
    }
    #didomi-notice-agree-button {
      background-color: #324EEF !important;
      color: white !important;
    }
  }
}
