[id="not-found"] {
  background-color: #ECF0FE;
  place-items: center;
  display: grid;
  > .container {
    max-height: 100%;
    text-align: center;
    padding-bottom: 60px;
    > video {
      max-width: 90vw;
      max-height: 80vh;
      @supports (min-height: 100dvh) {
        max-height: 80dvh;
      }
    }
    > h1 {
      margin-top: -20px;
      color: var(--primary-color);
      font-weight: 600;
      @media screen and (min-width: 769px) {
        margin-top: -30px;
        font-size: 46px;
      }
    }
    > .button {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: white;
      margin-top: -30px;
      @media screen and (min-width: 1025px) {
        font-size: 22px;
        line-height: 1;
        padding: 10px 24px;
        margin-top: 0px;
      }
    }
  }
}