[id='root'] {
  overflow: hidden;
  height: 100%;
  width: 100%;
  .page {
    scroll-behavior: smooth;
    overflow: auto;
    height: 100vh;
    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
    padding: 1px;
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: rgba(gray, 0.5);
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: gray;
    cursor: pointer;
  }
  .button,
  button {
    display: inline-block;
    border: 1px solid #d7d7d7;
    padding: 8px 24px;
    color: var(--base-font-color);
    border-radius: 40px;
    background-color: transparent;
    cursor: pointer;
  }
  .container {
    max-width: var(--breakpoint-laptop);
    width: 100%;
    margin: 0 auto;
    padding: 1px 10px;
    @media screen and (min-width: 1720px) {
      max-width: var(--breakpoint-desktop);
    }
  }
  .ellipsis {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100%;
  }
  .truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: var(--line-clamp, 4);
    line-clamp: var(--line-clamp, 4);
  }
  /* Media queries */
  @media screen and (min-width: 481px) {
    .only-mobile {
      display: none;
    }
  }
  @media screen and (min-width: 769px) {
    .only-responsive {
      display: none;
    }
  }
  @media screen and (min-width: 1025px) {
  }
  @media screen and (min-width: 1441px) {
  }
  @media screen and (min-width: 1921px) {
    .container {
      max-width: 1660px;
    }
  }
}

@keyframes fadeEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes translateEnter {
  0% {
    margin-top: -5vh;
  }
  100% {
    margin-top: 0;
  }
}
