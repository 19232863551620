.tilt-image {
  aspect-ratio: 1;
  margin: auto;
  border-radius: 10%;
  box-shadow: 17px 10px 19px #00000044;
  position: relative;
  background-size: cover;
  background-position: center;
  transform-style: preserve-3d;
  will-change: transform;
  max-width: 70vw;
  max-height: 55vh;
  animation: float 3s ease-in-out infinite both;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg);
  transition: transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
  &:hover {
    animation: none;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
